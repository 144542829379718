import 'antd/dist/antd.less';
import React from 'react';
import {
  AppContext,
  APP_CONTEXT_OPTIONS,
  firebase,
  next,
  sentry,
} from '@vette/frontend-utils';
import {
  ErrorFallback,
  GlobalStyles,
  RestrictMobile,
  theme,
  WarningProvider,
  ZendeskWidget,
  NoData,
  RedirectToSettings,
} from '@vette/ui-components';

import Head from 'next/head';

import * as redirects from '../config/redirects';
import {
  vetter,
  PushNotificationMessaging,
  SessionWatchers,
} from '@vette/pages';

import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';

const measurementId = process.env.NEXT_PUBLIC_VETTER_MEASUREMENT_ID;

sentry.setup('vetter-platform');
export default next.app.createCustomApp({
  ErrorFallback,
  wrapComponent: node => (
    <ConfigProvider renderEmpty={() => <NoData />}>
      <AppContext.Provider value={APP_CONTEXT_OPTIONS.vetter}>
        <ThemeProvider
          theme={{ ...theme, config: { ...theme.config, allowDarkMode: true } }}
        >
          <WarningProvider>
            <GlobalStyles />
            {measurementId && (
              <next.RouteTracker measurementId={measurementId} />
            )}
            <firebase.RedirectGuard
              loggedInRedirects={redirects.loggedInRedirects}
              loggedOutRedirects={redirects.loggedOutRedirects}
            >
              <Head>
                <title>Vette | Vetters Platform</title>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <meta pingdom="106B1C6B4E9010EB5556E21E85C8C9487C9896B7C6B3D0BDE45E8B440F5895C4" />
                }
              </Head>
              <firebase.FirebaseClaims>
                {({ id }) => id && <PushNotificationMessaging userId={id} />}
              </firebase.FirebaseClaims>
              <RestrictMobile exceptionRoutes={['/login', '/signup']}>
                {node}
              </RestrictMobile>
              <next.ClientSide>
                <ZendeskWidget />
                <firebase.FirebaseClaims required={{ id: true }}>
                  {({ id }) => (
                    <>
                      <SessionWatchers userId={id} />
                      <vetter.DisabledWatcher userId={id} />
                      <RedirectToSettings userId={id} />
                    </>
                  )}
                </firebase.FirebaseClaims>
              </next.ClientSide>
            </firebase.RedirectGuard>
          </WarningProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </ConfigProvider>
  ),
});
